
/* sub-visual */
.sub-visual {
    position:relative;
    display: flex;
    align-items: center;
    text-align: center;
    height: 200px;
    padding-top: calc(var(--header-body-height) /2);
}
.sub-visual__tit {
    font-size: 3rem;
    font-weight:700;
    color:#fff;
}
.sub-visual__bg {
    position: absolute;
    top:0;
    left: 50%;
    z-index: -1;
    -webkit-transform: translate(-50%,0); -ms-transform: translate(-50%,0); transform: translate(-50%,0);
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 300px;
        padding-top:0;
    }
    .sub-visual__tit {
        font-size: 4rem;
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        height: 500px;
        padding-top: 0;
    }
    .sub-visual__tit {
        font-size: 6.4rem;
    }
}
@media (min-width: 1200px) {
}

::v-deep{
    .sub-tab{
        .container{
            max-width: var(--container);
            padding: 0;
        }
        background-color: var(--v-grey-lighten5);
        .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) {
            color: var(--v-grey-darken4);
            background-color: var(--v-grey-lighten5);
        }
        .v-tab--active {
            font-weight: var(--tab-active-font-weight);
            color: #fff;
            background-color: var(--v-primary-base)
        }
        .v-tab{
            font-size: var(--tab-font-size);
            flex: 1 1 auto;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep{
        .sub-tab{
            .v-tab{
                flex: 1 1 0;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
